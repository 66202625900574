import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 导入全局初始化样式
import './style/reset.css'

// 按需导入element-ui
import 'element-ui/lib/theme-chalk/index.css';
import {
  Button,
  Carousel,
  CarouselItem
} from 'element-ui';
Vue.component(Button.name, Button);
Vue.component(Carousel.name, Carousel);
Vue.component(CarouselItem.name, CarouselItem);

import AOS from 'aos'
import 'aos/dist/aos.css'
AOS.init()

// 引入多语言 I18n 
// import i18n from './lang/index'
// Vue.use({
//   i18n: (key, value) => i18n.t(key, value)
// });

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')