import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    navtemindex: 0
  },
  mutations: {
    // 改变navtemindex的值，这个值关系到头部，底部样式和路由切换
    NAVTEMID(state, id) {
      state.navtemindex = id
    }
  },
  actions: {},
  modules: {}
})