<template>
  <div class="FootBox">
    <div class="telMenu">
      <div class="telmenuContent">
        <!-- left部分 logo和 QQ WeChat -->
        <div class="leftLOGOqq">
          <div class="leftlogo">
            <img src="https://qn.icgushi.com/NANJIXIONGLOGO.png" alt="" />
          </div>
          <!-- qq wechat 微博 -->
          <div class="leftqw">
            <!-- https://qn.icgushi.com/NANJIXIONGSHOUA%3AQQ.png -->
            <img src="https://qn.icgushi.com/NANJIXIONG/QQ.png" alt="" />

            <!-- <a
              href="tencent://message/?uin=你要联系的QQ号码&Site=admin5.com&Menu=yes"
            >
             
            </a> -->
            <!-- https://qn.icgushi.com/NANJIXIONGSHOUA%3AWEI.png -->
            <img src="https://qn.icgushi.com/NANJIXIONG/WECHAT.png" alt="" />
            <!--https://qn.icgushi.com/NANJIXIONGSHOUA%3AWEIBO.png  -->
            <img src="https://qn.icgushi.com/NANJIXIONG/WEBO.png" alt="" />
          </div>
        </div>
        <!-- 目录 -->
        <div class="menulistbox">
          <ul>
            <li class="itemtitle">首页</li>
            <li @click="tworoute('/', 0, 1)">公司简介</li>
            <li @click="tworoute('/', 0, 1)">行业平台</li>
            <li @click="tworoute('/', 0, 1)">主营业务</li>
            <li @click="tworoute('/', 0, 1)">代理品牌</li>
          </ul>

          <ul>
            <li class="itemtitle">公司介绍</li>
            <li @click="tworoute('/companyprofile', 1, 1)">公司背景</li>
            <li @click="tworoute('/companyprofile', 1, 2)">组织架构</li>
          </ul>

          <ul>
            <li class="itemtitle">企业文化</li>
            <li @click="tworoute('/culture', 2, 1)">企业愿景</li>
            <li @click="tworoute('/culture', 2, 2)">员工愿景</li>
          </ul>

          <ul>
            <li class="itemtitle">联系我们</li>
            <li @click="tworoute('/contactus', 4, 1)">电话</li>
            <li @click="tworoute('/contactus', 4, 1)">邮箱</li>
          </ul>
        </div>
      </div>
    </div>



<!-- <div style="width:300px;margin:0 auto; padding:20px 0;">
     <a target="_blank" href=" " style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">< img src="" style="float:left;"/><p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;"></p ></ a>
    </div> -->


    
    <div class="recordBox">
      <div class="recordContent">
        <a href="https://beian.miit.gov.cn" target="_blank">粤ICP备2022036815</a>
         Copyright © 2022 深圳市南极熊电子科技有限公司
      </div>
      <div class="altar">
        <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030702004820">
          <img src="https://qn.icgushi.com/ICWANGZHAN/UU.png" alt="" class="alterImg">
          粤公网安备 44030702004820号
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    // 页面跳转  path为跳转路径  titleindex为nav导航栏处切换样式flag
    // subindex 为大目录nav下的小目录的切换flag
    tworoute(path, titleindex, subindex) {
      this.$store.commit("NAVTEMID", titleindex);
      this.$router.push({
        path: path,
        query: {
          subindex,
          qu: Math.floor(Math.random() * 100),
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.FootBox {
  width: 100%;
  height: 360px;
  .telMenu {
    width: 100%;
    height: 400px;
    background: #f2f2f2;
    display: flex;
    justify-content: center;
    align-items: center;
    // margin: 0 auto;
    .telmenuContent {
      width: 1320px;
      height: 280px;
      margin: 0 auto;
      // border: 1px solid red;
      display: flex;
      justify-content: space-between;
      .leftLOGOqq {
        width: 350px;
        height: 200px;
        // border: 1px solid green;
        .leftlogo {
          width: 318px;
          height: 87px;
          text-align: center;
          border-bottom: 2px solid #ccc;
          img {
            width: 282px;
            height: 65px;
          }
        }
        .leftqw {
          width: 318px;
          height: 100px;
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          img {
            width: 60px;
            height: 60px;
          }
        }
      }
      .menulistbox {
        width: 850px;
        height: 300px;
        // border: 1px solid blue;
        display: flex;
        justify-content: space-between;
        ul {
          li {
            width: 150px;
            height: 40px;
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            // a {

            //   color: #555555;
            // }
          }
          li:hover {
            text-decoration: underline;
            color: #004196;
          }
        }
        .itemtitle {
          font-size: 24px;
          margin-bottom: 32px;
          // margin-right: 100px;
        }
        .itemtitle:hover {
          color: #333333;
          text-decoration: none;
        }
      }
    }
  }
  .recordBox {
    width: 100%;
    height: 100px;
    background: #d7d7d7;
    .recordContent {
      width: 1320px;
      height: 50px;
      margin: 0 auto;
      line-height: 50px;
      text-align: center;
      font-size: 18px;
    }
    .altar{
       width: 1320px;
      height: 50px;
      margin: 0 auto;
      line-height: 50px;
      text-align: center;
      font-size: 18px;
      .alterImg{
        width: 20px;
        height: 20px;
      }
    }
  }
}
</style>
