<template>
  <div id="app">
    <Head></Head>
    <router-view />
    <Foot></Foot>
  </div>
</template>

<script>
import Head from "./components/tohead/index";
import Foot from "./components/tofoot/index";
export default {
  components: {
    Head,
    Foot,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  // text-align: center;
  // color: #2c3e50;
}
</style>
