import Vue from 'vue'
import VueRouter from 'vue-router'
// 首页
import SouthPole from '../views/southpoleindex.vue'

Vue.use(VueRouter)

const routes = [
  // 首页
  {
    path: '/',
    name: 'Home',
    component: SouthPole
  },
  // 企业文化
  {
    path: '/culture',
    name: 'culture',
    component: () => import('../views/enterpriseCulture.vue')
  },
  // 公司简介
  {
    path: '/companyprofile',
    name: 'companyprofile',
    component: () => import('../views/companyprofile.vue')
  },
  // 代理品牌
  {
    path: '/agentbrand',
    name: 'agentbrand',
    component: () => import('../views/agentBrand.vue')
  },
  // 联系我们
  {
    path: '/contactus',
    name: 'contactus',
    component: () => import('../views/contactUs.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior:()=>({y:0})
})

export default router