<template>
  <div class="headbox">
    <div class="headcontent">
      <div class="headleft" @click="gotoindex">
        <img src="https://qn.icgushi.com/NANJIXIONGLOGO.png" alt="" />
      </div>
      <div class="headright">
        <div
          class="titleToPath"
          v-for="item in toptitle"
          :key="item.name"
          :class="temindex == item.id ? 'activeitem' : ''"
          @click="changetemindex(item)"
        >
          {{ item.name }}
        </div>
        <div class="titleToPath">中/英</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      toptitle: [
        {
          id: 0,
          name: "首页",
          topath: "Home",
          toquery: "",
        },
        {
          id: 1,
          name: "公司简介",
          topath: "companyprofile",
          toquery: 1,
        },
        {
          id: 2,
          name: "企业文化",
          topath: "culture",
          toquery: 1,
        },
        {
          id: 3,
          name: "代理品牌",
          topath: "agentbrand",
          toquery: "",
        },
        {
          id: 4,
          name: "联系我们",
          topath: "contactus",
          toquery: "",
        },
      ],
      temindex: null,
    };
  },
  created() {
    // this.temindex = 0;
    
    this.temindex = sessionStorage.getItem("navitem");
    // console.log("aaaa");
  },
  methods: {
    // 改变样式 和  路由
    changetemindex(e) {
      this.temindex = e.id;
      this.$store.commit("NAVTEMID", e.id);
      sessionStorage.setItem("navitem", e.id)
      this.temindex = this.temindex || sessionStorage.getItem("navitem");
      // this.$store.state.navtemindex = e.id;
      this.$router.push({
        name: e.topath,
        query: {
          subindex: e.toquery,
          qu: Math.floor(Math.random() * 100),
        },
      });
    },
    gotoindex() {
      this.$store.commit("NAVTEMID", 0);
      this.$router.push({
        name: "Home",
        query: {
          qu: Math.floor(Math.random() * 100),
        },
      });
      this.temindex = 0
       this.$store.commit("NAVTEMID", 0);
       sessionStorage.setItem("navitem", 0)
    },
  },
  computed: {
    temid() {
      let a = this.$store.state.navtemindex || sessionStorage.getItem("navitem");
      return a;
      // return this.$store.state.navtemindex;
    },
  },
};
</script>

<style scoped lang="scss">
.headbox {
  width: 100%;
  height: 108px;
  background: #fff;
  // position: sticky;
  // top: 0;
  // background: violet;
  .headcontent {
    width: 1320px;
    height: 108px;
    margin: 0 auto;
    // background: yellowgreen;
    display: flex;
    align-items: center;
    // LOGO
    .headleft {
      width: 282px;
      height: 65px;
      // display: flex;
      // justify-content: center;
      // align-items: center;
      margin-right: 143px;
      img {
        width: 282px;
        height: 65px;
      }
    }
    // 目录
    .headright {
      flex: 1;
      height: 108px;
      display: flex;
      .titleToPath {
        width: 149px;
        height: 108px;
        text-align: center;
        line-height: 108px;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        cursor: pointer;
      }
      .activeitem {
        background: #a6dce3;
        border-radius: 8px;
      }
    }
  }
}
</style>
