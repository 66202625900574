<template>
  <div class="indexBox">
    <!-- 轮播图 -->
    <div class="swiperBox">
      <el-carousel height="660px">
        <el-carousel-item v-for="item in swiperList" :key="item">
          <img :src="item" alt="" class="swiperimg" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 公司简介 -->
    <div class="introductory">
      <!-- title  and bar -->
      <div class="titleABar">
        <div class="companyTile">公司简介</div>
        <div class="titlebeBar"></div>
      </div>

      <div class="companydescribe">
        <div
          class="companytext"
          data-aos="fade-right"
          :data-aos-delay="100"
          :data-aos-offset="50"
        >

<!-- 南极熊科技有限公司是美国SMARTEL ELECTRONICS (ASIA) CO., LIMITED 在中国深圳成立的一家创新型电子元器件混合型分销商。
我们致力于为原始设备制造商（OEM），原始品牌生产商（OBM），电子产品合约生产商，EMS（Electronics Manufacturing Service）
提供商以及方案设计商（Design House）提供全面的电子元器件供应链成本优化解决方案与供应链服务。 -->



          <div class="companyName">南极熊科技有限公司</div>
          <div class="companycontext">
          南极熊科技有限公司是美国<br />
          SMARTEL ELECTRONICS (ASIA) CO., LIMITED在中国深圳成立的一家<br />
           创新型电子元器件混合型分销商。我们致力于为原始设备制造商（OEM），<br />
            原始品牌生产商（OBM），电子产品合约生产商，EMS<br />
            （Electronics Manufacturing Service）提供商以及方案设计商<br />
            （Design House）提供全面的电子元器件供应链成本优化解决方案<br />
            与供应链服务。
          </div>
        </div>
        <img
          data-aos="fade-left"
          :data-aos-delay="100"
          :data-aos-offset="50"
          class="companyRightimg"
          src="https://qn.icgushi.com/NANJIXIONGSHOUA%3AJIANJIE.png"
          alt=""
        />
      </div>
    </div>
    <!-- 行业平台 -->
    <div class="IndustryPlatform">
      <div class="IPFbox">
        <!-- title  and bar -->
        <div class="titleABar">
          <div class="companyTile">行业平台</div>
          <div class="titlebeBar"></div>
        </div>
        <!-- 解释 -->
        <div class="explain">
          我们以消费电子、手机通讯、平板、车载、工控、医疗等电子行业市场为服务对象，专注于为国内外知名生产厂家提供全面<br />
          的优质电子元器件产品、产品应用支持、方案设计、售后技术及物流等方面的专业服务。
        </div>
        <!-- 应用场景 -->
        <div
          class="applicationS"
          data-aos="fade-up"
          :data-aos-delay="100"
          :data-aos-offset="50"
        >
          <!-- 手机 -->
          <div class="botmpic">
            <div class="toptext"></div>
          </div>
          <div class="botmpicA">
            <div class="toptextA"></div>
          </div>
          <div class="botmpicB">
            <div class="toptextB"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- 主营业务 -->
    <div class="MainBusiness">
      <div class="MBtext">
        <!-- title  and bar -->
        <div class="titleABar">
          <div class="companyTile">主营业务</div>
          <div class="titlebeBar"></div>
        </div>
        <!-- 解释 -->
        <div
          class="picAndtext"
          data-aos="fade-right"
          :data-aos-delay="100"
          :data-aos-offset="50"
        >
          <div
            class="itembox"
            v-for="item in mainbusinessList"
            :key="item.text"
          >
            <img :src="item.img" alt="" class="img" />
            <div class="tt">{{ item.text }}</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 代理品牌 -->
    <div class="agentBrand">
      <div class="ABtext">
        <!-- title  and bar -->
        <div class="titleABar">
          <div class="companyTile">行业平台</div>
          <div class="titlebeBar"></div>
        </div>
        <div
          class="brandimgbox"
          data-aos="fade-left"
          :data-aos-delay="100"
          :data-aos-offset="50"
        >
          <img
            src="https://qn.icgushi.com/NANJIXIONGSHOUA%3APINPAI.png"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 轮播图
      swiperList: [
        // "https://qn.icgushi.com/NANJIXIONGSHOUA%3ABANNER.png",
        // "https://qn.icgushi.com/NANJIXIONGSHOUB%3ABANNER.png",
        // "https://qn.icgushi.com/NANJIXIONGSHOUC%3ABANNER.png",
        // 'https://qn.icgushi.com/NANJIXIONG/SHOUA.png',
        'https://qn.icgushi.com/AA.png',
        'https://qn.icgushi.com/NANJIXIONG/SHOUB.png',
        'https://qn.icgushi.com/NANJIXIONG/SHOUCC.png'
        
      ],
      // 主营业务
      mainbusinessList: [
        {
          img: "https://qn.icgushi.com/NANJIXIONGSHOUA%3AYEWUA.png",
          text: "授权代理",
        },

        {
          img: "https://qn.icgushi.com/NANJIXIONGSHOUA%3AYEWUB.png",
          text: "网上商城",
        },
        {
          img: "https://qn.icgushi.com/NANJIXIONGSHOUA%3AYEWUC.png",
          text: "芯片销售",
        },
        {
          img: "https://qn.icgushi.com/NANJIXIONGSHOUA%3AYEWUD.png",
          text: "芯片测试",
        },
        {
          img: "https://qn.icgushi.com/NANJIXIONGSHOUA%3AYEWUE.png",
          text: "自媒体",
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.indexBox {
  width: 100%;

  // 轮播图
  .swiperBox {
    width: 100%;
    height: 660px;
    .swiperimg {
      width: 100%;
      height: 660px;
    }
  }
  // 公共使用的类   标题和下面的bar
  .titleABar {
    width: 1320px;
    height: 87px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .companyTile {
      width: 292px;
      height: 48px;
      text-align: center;
      font-size: 34px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 48px;
      margin-bottom: 30px;
    }
    .titlebeBar {
      width: 292px;
      height: 9px;
      background-image: url("https://qn.icgushi.com/NANJIXIONGSHOUA%3AZHUANGSHI.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
  }

  // 公司简介
  .introductory {
    margin: 0 auto;
    margin-top: 130px;
    width: 1320px;
    height: 718px;

    .companydescribe {
      margin-top: 73px;
      width: 1320px;
      height: 421px;
      display: flex;
      justify-content: space-between;
      .companyRightimg {
        width: 660px;
        height: 421px;
      }
      .companytext {
        width: 560px;
        height: 421px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        // align-items: center;
        .companyName {
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          margin-bottom: 30px;
        }
        .companycontext {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 30px;
        }
      }
    }
  }
  // 行业平台
  .IndustryPlatform {
    width: 100%;
    height: 642px;
    background: #f3f2f3;
    display: flex;
    justify-content: center;
    align-items: center;
    .IPFbox {
      width: 1320px;
      height: 430px;
      // margin: 0 auto;
      .explain {
        width: 1320px;
        height: 65px;
        margin-top: 29px;
        color: #666666;
        text-align: center;
        line-height: 30px;
      }
      .applicationS {
        width: 1320px;
        height: 200px;
        margin-top: 37px;
        display: flex;
        justify-content: space-between;
        .botmpic {
          width: 400px;
          height: 200px;
          background-image: url("https://qn.icgushi.com/NANJIXIONGSHOUA%3ASHOUJI.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          .toptext {
            display: none;
            width: 400px;
            height: 200px;
            background-image: url("https://qn.icgushi.com/NANJIXIONGSHOUA%3ASHOUJIB.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
          }
        }
        .botmpic:hover {
          .toptext {
            display: block;
          }
        }

        .botmpicA {
          width: 400px;
          height: 200px;
          background-image: url("https://qn.icgushi.com/NANJIXIONGSHOUA%3APINGBAN.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          .toptextA {
            display: none;
            width: 400px;
            height: 200px;
            background-image: url("https://qn.icgushi.com/NANJIXIONGSHOUA%3APINGBANB.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
          }
        }
        .botmpicA:hover {
          .toptextA {
            display: block;
          }
        }

        .botmpicB {
          width: 400px;
          height: 200px;
          background-image: url("https://qn.icgushi.com/NANJIXIONGSHOUA%3ACHE.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          .toptextB {
            display: none;
            width: 400px;
            height: 200px;
            background-image: url("https://qn.icgushi.com/NANJIXIONGSHOUA%3ACHEB.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
          }
        }
        .botmpicB:hover {
          .toptextB {
            display: block;
          }
        }
      }
    }
  }
  // 主营业务
  .MainBusiness {
    width: 100%;
    height: 623px;
    background: #d7d7d7;
    display: flex;
    justify-content: center;
    align-items: center;
    .MBtext {
      width: 1320px;
      height: 400px;

      .picAndtext {
        width: 1320px;
        height: 250px;
        margin-top: 57px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .itembox {
          width: 215px;
          height: 250px;
          text-align: center;
          .tt {
            margin-top: 22px;
            font-size: 36px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 50px;
          }
        }
      }
    }
  }
  // 代理品牌
  .agentBrand {
    width: 100%;
    height: 830px;
    display: flex;
    justify-content: center;
    align-items: center;
    .ABtext {
      width: 1320px;
      height: 600px;
      .brandimgbox {
        // margin-top: 79px;
        width: 1222px;
        height: 467px;
        margin: 79px auto 0;
        // border: 1px solid red;
      }
    }
  }
}
</style>
<style scoped>
/deep/.el-carousel__arrow {
  width: 65px;
  height: 65px;
  font-size: 30px;
}
</style>
